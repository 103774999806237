import React from "react"
import { Link } from "gatsby"

import { SEO, Container } from "../components/common"

import "./404.css"

const NotFoundPage = () => (
  <Container>
    <SEO title="Page Not Found" />
    <div className="error-page">
      <img alt="404" src={require("../assets/image/404.svg")} />
      <div className="error-action">
        <h2>We Messed Up, Sorry!</h2>
        <p>
          Perhaps this page does not exist, misspelled URL or just broken!
          <br /> Browse other <Link to="/">gradients</Link> or{" "}
          <Link to="/contact">contact</Link> us!
        </p>
      </div>
    </div>
  </Container>
)

export default NotFoundPage
